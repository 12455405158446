import React from 'react';

function CommercialTwo() {
    return(
        <div>
            <div className="commercial__header">
                
            </div>
        </div>
    )
}

export default CommercialTwo;
