export const SidebarData = [
    {
        title: 'Commercial',
        path: '/Commercial',
        cName: 'nav-text'
    },
    {
        title: 'Technical',
        path: '/Technical',
        cName: 'nav-text'
    },
    {
        title: 'Personal',
        path: '/Personal',
        cName: 'nav-text'
    },
    {
        title: 'About',
        path: '/About',
        cName: 'nav-text'
    }
];
