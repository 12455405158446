import React from 'react';

function LogoDark() {
    return (
        <svg width="192" height="132" viewBox="0 0 192 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.6476 131.344L66.9177 130.877C67.2747 131.192 67.7382 131.361 68.2148 131.349C68.8943 131.349 69.309 131.076 69.309 130.634C69.309 130.193 68.8484 129.966 68.1901 129.774L67.8954 129.691C67.1788 129.494 66.6035 129.181 66.6035 128.422C66.6035 127.606 67.2759 127.062 68.2854 127.062C68.8461 127.044 69.3929 127.238 69.8155 127.606L69.5084 128.077C69.1749 127.774 68.7366 127.611 68.2854 127.623C67.6977 127.623 67.313 127.905 67.313 128.327C67.313 128.78 67.7295 128.945 68.2148 129.086L68.5219 129.168C69.2278 129.373 70.0202 129.73 70.0202 130.576C70.0202 131.381 69.3143 131.917 68.2554 131.917C67.6652 131.941 67.0887 131.735 66.6476 131.344Z" fill="#C09553"/>
            <path d="M80.9503 127.688H79.466V131.847H78.7813V127.688H77.2988V127.127H80.952L80.9503 127.688Z" fill="#C09553"/>
            <path d="M88.5625 130.112V127.12H89.2402V130.087C89.2402 130.839 89.7696 131.349 90.5709 131.349C91.3721 131.349 91.8892 130.839 91.8892 130.087V127.127H92.574V130.119C92.574 131.205 91.7745 131.919 90.5709 131.919C89.3673 131.919 88.5625 131.198 88.5625 130.112Z" fill="#C09553"/>
            <path d="M104.741 129.494C104.741 130.902 103.82 131.847 102.431 131.847H100.705V127.127H102.431C103.82 127.127 104.741 128.07 104.741 129.494ZM104.035 129.494C104.035 128.408 103.384 127.688 102.405 127.688H101.39V131.284H102.406C103.386 131.284 104.037 130.566 104.037 129.494H104.035Z" fill="#C09553"/>
            <path d="M112.635 127.127H113.318V131.847H112.635V127.127Z" fill="#C09553"/>
            <path d="M121.205 129.487C121.205 128.033 122.178 127.062 123.63 127.062C125.082 127.062 126.05 128.033 126.05 129.487C126.05 130.941 125.077 131.912 123.63 131.912C122.183 131.912 121.205 130.941 121.205 129.487ZM125.345 129.487C125.345 128.371 124.654 127.623 123.63 127.623C122.606 127.623 121.916 128.371 121.916 129.487C121.916 130.603 122.601 131.349 123.63 131.349C124.659 131.349 125.345 130.603 125.345 129.487Z" fill="#C09553"/>
            <path d="M115.401 68.6336H77.2503L58.1777 35.6877L77.2503 2.74359H115.401L134.48 35.6877L115.401 68.6336ZM78.4698 66.5218H114.185L132.038 35.6877L114.185 4.84659H78.4698L60.615 35.6877L78.4698 66.5218Z" fill="#C09553"/>
            <path d="M116.99 71.3737H75.6629L55.002 35.6877L75.6629 0H116.99L137.658 35.6877L116.99 71.3737ZM76.1411 70.5483H116.512L136.694 35.6877L116.512 0.825363H76.1411L55.9585 35.6877L76.1411 70.5483Z" fill="#C09553"/>
            <path d="M77.2441 17.0071H87.4167V19.1189C86.412 19.2905 85.4326 19.586 84.5011 19.9988C82.7363 20.8071 81.3686 21.7879 80.3979 22.9412C79.4809 24.008 78.784 25.2447 78.3472 26.5806C77.9957 27.6672 77.7122 28.7745 77.4983 29.8961L77.2441 29.8292V17.0071Z" fill="#C09553"/>
            <path d="M115.409 29.8327L115.157 29.8996C114.95 28.7795 114.677 27.6724 114.34 26.5841C113.926 25.2448 113.239 24.0056 112.321 22.9447C111.351 21.7926 109.985 20.8118 108.223 20.0023C107.265 19.5765 106.257 19.2749 105.223 19.1048V17.0071H115.402L115.409 29.8327Z" fill="#C09553"/>
            <path d="M93.7049 17.2482H93.7031V17.0071H98.9447V31.9428L93.7049 24.3896V17.2482Z" fill="#C09553"/>
            <path d="M106.266 58.409V58.6518H86.3887V58.409H88.2841C88.993 58.4123 89.7011 58.3605 90.4019 58.2541C91.0291 58.166 91.6246 57.9242 92.135 57.5502C92.6733 57.1486 93.0809 56.5977 93.3068 55.9663C93.5821 55.28 93.711 54.3473 93.711 53.177V34.0388L98.9508 41.6905V53.1893C98.9508 54.3596 99.0973 55.2923 99.392 55.9786C99.6324 56.6008 100.035 57.1476 100.559 57.5625C101.067 57.944 101.667 58.1866 102.299 58.2664C103.007 58.3718 103.723 58.4235 104.439 58.4213L106.266 58.409Z" fill="#C09553"/>
            <path d="M79.3454 110.785C79.3454 112.749 80.5208 113.073 81.7597 113.073V113.485H73.7227V113.073C74.958 113.073 76.1052 112.756 76.1052 110.785V93.0108C76.1052 91.0469 74.9616 90.7231 73.7227 90.7231V90.3112H81.7597V90.7231C80.5243 90.7231 79.3454 91.0398 79.3454 93.0108V110.785Z" fill="#C09553"/>
            <path d="M116.898 106.604L116.192 113.478H98.916V113.034C100.187 113.034 101.712 113.003 101.712 110.785V93.0443C101.712 90.8586 99.9643 90.7319 98.916 90.7319V90.32H107.43V90.7635C106.095 90.7635 105.135 90.9536 105.135 93.1393V110.817C105.135 112.496 106.311 113.066 107.454 113.066C109.837 113.066 114.604 113.161 116.224 106.604H116.898Z" fill="#C09553"/>
            <path d="M140.185 102.041V110.88C140.185 112.971 142.504 113.066 142.567 113.066H144.632C149.016 113.066 150.985 110.152 151.241 106.984H151.75L151.305 113.478H134.054V113.066C135.229 113.066 136.877 112.622 136.877 110.778V101.597H140.181L140.185 102.041ZM136.298 91.9989C136.298 91.9989 135.441 90.7319 134.043 90.7319V90.32H150.691L151.102 96.1486H150.627C149.99 92.8542 147.544 90.7319 144.05 90.7319H138.436L145.299 100.663C145.744 99.9956 145.934 99.0999 145.934 98.3062L146.443 98.1953V106.065H145.934" fill="#C09553"/>
            <path d="M191.999 113.066V113.478H185.36L178.085 102.452H175.002V110.754C175.002 113.193 177.196 113.066 178.085 113.066V113.478H168.908V113.066C169.702 113.066 171.704 113.161 171.704 110.778V102.041L171.716 101.039L171.704 91.9479C171.704 91.9479 170.306 90.7319 168.94 90.7319V90.32H177.764C180.464 90.32 186.059 90.8902 186.059 96.3387C186.059 99.6648 183.263 101.375 181.516 102.041C184.153 106.088 187.552 111.862 190.919 112.876C191.274 112.967 191.635 113.03 191.999 113.066ZM182.215 96.3387C182.215 93.1393 181.294 90.7319 177.768 90.7319H174.986V101.191C174.986 101.191 179.398 101.381 180.281 100.969C181.366 99.9112 182.215 98.7461 182.215 96.3387Z" fill="#C09553"/>
            <path d="M53.9305 110.152C53.8669 110.025 45.2563 89.9523 45.2563 89.9523C45.2563 89.9523 38.2993 108.255 38.077 108.782C37.3463 110.525 36.3933 113.059 33.9473 113.059V113.471H41.8573V113.059H41.8273C39.9777 113.043 38.7282 111.157 39.3671 109.427L40.6978 105.821H48.7101L50.4396 110.208C51.0114 111.919 50.2173 113.059 48.5336 113.059V113.471H56.5707V113.059C55.3282 113.066 55.0423 112.686 53.9305 110.152ZM48.1807 104.416H41.2201L44.5963 95.2652L48.1807 104.416Z" fill="#C09553"/>
            <path d="M0 90.2884H5.66512V91.4639C5.10535 91.5598 4.55987 91.7253 4.04147 91.9567C3.16977 92.3271 2.39197 92.8868 1.76484 93.5951C1.25372 94.1891 0.865637 94.8781 0.622987 95.6224C0.424632 96.2268 0.263728 96.8427 0.141187 97.4667L0 97.4298V90.2884Z" fill="#C09553"/>
            <path d="M21.2528 97.4298L21.1134 97.4667C20.9981 96.8436 20.8461 96.2277 20.6581 95.6224C20.4283 94.8763 20.0455 94.1859 19.5339 93.5951C18.9045 92.887 18.125 92.3273 17.2519 91.9567C16.72 91.7201 16.1603 91.5516 15.5859 91.4551V90.2884H21.2528V97.4298Z" fill="#C09553"/>
            <path d="M9.16778 90.4221H9.16602V90.2884H12.0851V98.6054L9.16778 94.3993V90.4221Z" fill="#C09553"/>
            <path d="M16.1628 113.342V113.478H5.09375V113.342H6.15265C6.54608 113.344 6.93904 113.315 7.32803 113.256C7.67804 113.208 8.01051 113.074 8.29516 112.865C8.5951 112.643 8.82229 112.337 8.94815 111.985C9.11936 111.487 9.19713 110.961 9.17758 110.435V99.7721L12.0949 104.033V110.435C12.0735 110.964 12.1569 111.492 12.3402 111.989C12.4735 112.335 12.6979 112.639 12.9896 112.869C13.2686 113.078 13.5968 113.211 13.9426 113.256C14.337 113.314 14.7352 113.343 15.1339 113.342H16.1628Z" fill="#C09553"/>
        </svg>
    )
}

export default LogoDark;
