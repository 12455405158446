import React from 'react';
import CommercialRowOne from "./CommercialRowOne";
// import {width} from "../../../../tailerOrg/wp-includes/js/codemirror/csslint";
// import Header from "../Header/Header";


// const images = [
//     {
//         id: 1,
//         class: "Eden",
//         src: "Images/commercial/M2.jpg",
//         style: {
//             position: "absolute",
//             width: "24%",
//             height: "47.7%",
//             left: "1%",
//             top: "1104px",
//         }
//     },
//     {
//         id: 2,
//         class: "Dva",
//         src: "Images/commercial/Rossetto2b_1.jpg",
//         style: {
//             position: "absolute",
//             width: "22%",
//             height: "47.7%",
//             left: "26.3%",
//             top: "1104px"
//         }
//     },
//     {
//         id: 3,
//         class: "Tri",
//         src: "Images/commercial/Social_02.jpg",
//         style: {
//             position: "absolute",
//             width: "24%",
//             height: "47.7%",
//             left: "49.5%",
//             top: "1104px"
//         }
//     },
//     {
//         id: 4,
//         class: "Chetiri",
//         src: "Images/commercial/Op_1.jpg",
//         style: {
//             position: "absolute",
//             width: "24%",
//             height: "47.7%",
//             left: "75%",
//             top: "1104px",
//         }
//     }
//
// ]

function ImageRow() {
    return (
        <div>
            <CommercialRowOne/>

        </div>
    )
}

export default ImageRow;
